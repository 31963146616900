export const siteMap = [
  {
    title: 'Medici',
    link: '/medic',
  },
  {
    title: 'Categorii',
    link: '/categories',
  },
  {
    title: 'Intrebari',
    link: '/questions',
  },
  {
    title: 'Articole',
    link: '/news',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
]
