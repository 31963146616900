const map = [
  {
    link: '/admin',
    title: 'Acasa',
  },
  { link: '/admin/categories', title: 'Categorii' },
  { link: '/admin/medic', title: 'Medici' },

  { link: '/admin/users', title: 'Utilizatori' },
]

export default map
